<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">group</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage groups</ActionButton>
      </div>
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
    
    <CardGroup
      class="border-box py-15 px-12 cardBg rounded-20"
      :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
      :description="description"
      :loadDetail="loading"
      :groupSlug="groupSlug"
      :group="detailGroup"
      @successUpdate="updateGroup"
      @reloadDetailGroup="goToLoadDetailGroup"
      @updateStatus="updateStatus"
      @attachDestinations="attachDestinations"
      @detachDestination="detachDestination"
    />
  
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import CardGroup from '@/components/cards/CardGroup'

import { getDetailGroup } from '@/api/group-routes'
import { changeItemsAfterUpdate } from '@/helper/app-helper'

export default {
  name: 'DetailGroup',
  components: { ActionButton, CancelButton, CardGroup },
  data: () => ({
    loading: false,
    groupSlug: null,
    onShowResetCaps: false,
    detailGroup: {},
    description: {
      title: 'Setup Your Group destination',
      text: []
    },
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.groupSlug = this.$route.params.slug
      this.goToLoadDetailGroup()
    }
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'ManageGroups' })
    },
    async goToLoadDetailGroup() {
      this.loading = true

      const { success, payload, message } = await getDetailGroup(this.groupSlug)

      if (success) {
        this.detailGroup = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    updateGroup(group) {
      this.detailGroup = group
    },
    updateStatus(destination) {
      this.detailGroup.targets = changeItemsAfterUpdate(this.detailGroup.targets, destination)
    },
    attachDestinations(destinations) {
      this.detailGroup.targets = this.detailGroup.targets.concat(destinations)
    },
    detachDestination(destinationSlug) {
      this.detailGroup.targets = this.detailGroup.targets.filter(destination => destination.slug !== destinationSlug)
    },
  },
}
</script>
